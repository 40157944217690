<template>
  <v-app>
    <v-main class="pt-0 pt-md-0" style=" font-family: lexendregular;">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import "./assets/style/font.css";
import "./assets/style/style.css";

export default {
  name: 'App',
  data: () => ({
  }),
}
</script>
