import { createStore } from 'vuex'
import router from '@/router';
import axios from 'axios'

export default createStore({
  state: {
    isLoggedIn: !!localStorage.getItem("token"),
    role:localStorage.getItem("role"),
  },
  getters: {
  },
  mutations: {
    loginUser(state, payload) {
      localStorage.setItem("token", payload);
      state.isLoggedIn = true
    },
    role(state, payload) {
      state.role = payload
    },
    logoutUser(state) {
      axios({
        method: "GET",
        url: "/user/logout",
        headers: {
          "token": localStorage.getItem("token")
        }
      }).then(response => {
        if (response.data) {
          state.isLoggedIn = false
          localStorage.clear();
          // localStorage.removeItem("token");
          router.push('/')
        }
      })
    },

  },
  actions: {
  },
  modules: {
  }
})
