import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';

import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import VueApexCharts from "vue3-apexcharts";
import VueElementLoading from "vue-element-loading";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

import ImageLoader from './components/common/ImageLoader'
loadFonts()
const app = createApp(App);
app.component("VueElementLoading", VueElementLoading);

// axios.defaults.baseURL = 'http://172.20.10.3:5051';
// app.config.globalProperties.baseURL = "http://172.20.10.3:5051";
// app.config.globalProperties.mediaURL = "http://172.20.10.3:5051/file/get?key="
// app.config.globalProperties.mediaURLs = "http://172.20.10.3:5051/wp?key="

axios.defaults.baseURL = 'https://www.rectopages.com/';
app.config.globalProperties.baseURL = "https://www.rectopages.com/";
app.config.globalProperties.mediaURL = "https://www.rectopages.com/wp/";



app.use(VueApexCharts);
app.component('QuillEditor', QuillEditor)
app.component('VueDatePicker', VueDatePicker);
app.component('ImageLoader', ImageLoader)

createApp(App)
  .use(VCalendar, {})
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app')
