import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'
import axios from 'axios'
const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../components/login/LoginPage.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../components/login/SignUpView.vue"),
  },
  {
    path: "/",
    name: "landingPage",
    component: () => import("../components/landingPage/LandingView.vue"),
  },
  {
    path: "/businessregister/",
    name: "businessregister",
    component: () => import("../components/landingPage/BusinessAddView.vue"),
  },
  {
    path: "/businesshome",
    name: "businesshome",
    meta: {
      requiresAuth: false
    },
    component: () => import("../components/businessHome/BusinessHome.vue"),
  },
  {
    path: "/SideBarPage",
    name: "SideBarPage",
    meta: {
      requiresAuth: true
    },
    component: () => import("../components/common/SideBarPage.vue"),
    children: [
      {
        path: "/businessadd",
        name: "businessadd",
        meta: {
          requiresAuth: true
        },
        component: () => import("../components/businessHome/AddBusiness.vue"),
      },
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          requiresAuth: true
        },
        component: () => import("../components/home/DashboardPage.vue"),
      },
      {
        path: "/ControlRoomDashboard",
        name: "ControlRoomDashboard",
        meta: {
          requiresAuth: true
        },
        component: () => import("../components/home/ControlRoomDashboard.vue"),
      },
      {
        path: "/home",
        name: "home",
        meta: {
          requiresAuth: true
        },
        component: () => import("../components/home/HomePage.vue"),
      },
      {
        path: "/Graph",
        name: "GraphPage",
        meta: {
          requiresAuth: true
        },
        component: () => import("../components/home/GraphPage.vue"),
      },
      {
        path: "/count",
        name: "count",
        meta: {
          requiresAuth: true
        },
        component: () => import("../components/count/CountPage.vue"),
      },
      {
        path: "/analytics",
        name: "analytics",
        meta: {
          requiresAuth: true
        },
        component: () => import("../components/analytics/AnalyticsPage.vue"),
      },
      {
        path: "/usermanagement",
        name: "usermanagement",
        meta: {
          requiresAuth: true
        },
        component: () => import("../components/usermanagement/userList.vue"),
      },
      {
        path: "/AlertPage",
        name: "AlertPage",
        meta: {
          requiresAuth: true
        },
        component: () => import("../components/alerts/AlertPage.vue"),
      },
      {
        path: "/ControlRoomUsers",
        name: "ControlRoomUsers",
        meta: {
          requiresAuth: true
        },
        component: () => import("../components/usermanagement/ControlRoomUsers.vue"),
      },
      {
        path: "/path",
        name: "path",
        meta: {
          requiresAuth: true
        },
        component: () => import("../components/paths/PathsPage.vue"),
      },
      {
        path: "/NewsPage",
        name: "NewsPage",
        meta: {
          requiresAuth: true
        },
        component: () => import("../components/news/NewsPage.vue"),
      },
      {
        path: "/NewsDetail",
        name: "NewsDetail",
        meta: {
          requiresAuth: true
        },
        component: () => import("../components/news/NewsDetail.vue"),
      },
      {
        path: "/EmergencyContact",
        name: "EmergencyContact",
        meta: {
          requiresAuth: true
        },
        component: () => import("../components/contact/EmergencyContact.vue"),
      },
      {
        path: "/Disclaimer",
        name: "Disclaimer",
        meta: {
          requiresAuth: true
        },
        component: () => import("../components/UserAgreement/AddDisclaimer.vue"),
      },
      {
        path: "/AboutPTR",
        name: "AboutPTR",
        meta: {
          requiresAuth: true
        },
        component: () => import("../components/UserAgreement/AboutPTR.vue"),
      },
      {
        path: "/AboutSabarimala",
        name: "AboutSabarimala",
        meta: {
          requiresAuth: true
        },
        component: () => import("../components/UserAgreement/AboutSabarimala.vue"),
      },
      {
        path: "/Guidelines",
        name: "Guidelines",
        meta: {
          requiresAuth: true
        },
        component: () => import("../components/UserAgreement/AddGuidelines.vue"),
      },

      {
        path: "/CalenderPage",
        name: "CalenderPage",
        meta: {
          requiresAuth: true
        },
        component: () => import("../components/calender/CalenderPage.vue"),
      },
      {
        path: "/CalenderPage2",
        name: "CalenderPage2",
        meta: {
          requiresAuth: true
        },
        component: () => import("../components/calender/CalenderPage2.vue"),
      },
      {
        path: "/FullCalender",
        name: "FullCalender",
        meta: {
          requiresAuth: true
        },
        component: () => import("../components/calender/FullCalender.vue"),
      },

      {
        path: "/UserWalk",
        name: "UserWalk",
        meta: {
          requiresAuth: true
        },
        component: () => import("../components/Walks/UserWalk.vue"),
      },
      {
        path: "/ScanPoints",
        name: "ScanPoints",
        meta: {
          requiresAuth: true
        },
        component: () => import("../components/Walks/ScanPoints.vue"),
      },



    ]
  },
  {
    path: "/:catchAll(.*)",
    name: "notfound",
    component: () => import("../components/notfound/NotFoundPage.vue"), // Create this component

  },

  {
    path: "/500",
    name: "servererror",
    component: () => import("../components/notfound/ServerError.vue"),
  },
]

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 500) {
      router.push({ name: 'servererror' });
    }
    return Promise.reject(error);
  }
);

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // If a saved position is available, use it
      return savedPosition;
    } else {
      // Scroll to the top of the page
      return { top: 0 };
    }
  }
})
router.beforeEach((to, from, next) => {
  if (store.state.isLoggedIn == true || to.matched.some(route => route.meta.requiresAuth == true)) {
    axios({
      method: 'get',
      url: '/validate/token',
      headers: {
        'token': localStorage.getItem('token')
      }
    }).then(response => {
      if (!response.data.status) {
        console.log("router logiut")
        this.$store.state.isLoggedIn = false;
        localStorage.clear();
        router.push("/dashboard");
        // this.$store.commit("logoutUser", true);
        return
      }
    })
      .catch(err => {
        var msg = err;
        console.log(msg)
      });
  }

  // if (to.matched.some((route) => route.meta.requiresAuth) && !store.state.isLoggedIn) {
  //   next({ name: "login", query: { redirect: to.fullPath } });
  // } 
  if (
    to.matched.some((route) => route.meta.requiresAuth == true) &&
    store.state.isLoggedIn == false
  ) {
    console.log("1");
    next({ name: "login", params: { lastPage: from } });
    return;
  }
  if (to.name === "login" && store.state.isLoggedIn == true && store.state.role == 'Admin') {
    console.log("2");
    next({ name: "dashboard" });
  }
  if (to.name === "login" && store.state.isLoggedIn == true && store.state.role == 'Control Room') {
    next({ name: "ControlRoomDashboard" });
  }

  if (
    to.matched.some((route) => route.meta.isAdmin == false) && from.name != "login" &&
    store.state.isLoggedIn == true && store.state.role == 'Admin'
  ) {
    next({ name: "dashboard" });
    return;
  }
  if (
    to.matched.some((route) => route.meta.isUser == false) && from.name != "login" &&
    store.state.isLoggedIn == true && store.state.role == 'Control Room'
  ) {
    next({ name: "ControlRoomDashboard" });
    return;
  }
  else {
    next();
  }
});



export default router
